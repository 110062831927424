import { FormInputText } from './formInputs';


function ShippingRequest(props: {defaultValue?: string}) {

  return (
    <FormInputText
      name="shippingRequest"
      label="Original Shipping Request"
      defaultValue={props.defaultValue}
    />
  )
}

export default ShippingRequest;
