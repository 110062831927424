import logo from '../lib/logo_mark_black.png';
import styles from '../App.module.scss';
import {
  Box,
  LinearProgress,
} from '@mui/material';

interface SetViewProps {
  progress: number;
}


function ContentLoading(props: SetViewProps) {
  const { progress } = props;
  
  return (
    <>
      <div className={styles.loadingLogoWrapper}>
        <img className={styles.densityLogo} src={logo} alt="Loading" />
      </div>
      <div className={styles.loadingLogoWrapper}>
        <Box sx={{ width: '400px' }}>
          <LinearProgress variant="determinate" value={progress} color="inherit" />
          <p>getting sensor info...</p>
        </Box>
      </div>
    </>
  )
}

export default ContentLoading;