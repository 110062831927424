import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Grid,
} from '@mui/material';
import { CustomCoreSensor } from '../lib/types';
import { CoreUser } from '@densityco/lib-api-types';
import { AddressForm, AddressDialog } from './AddressForm';
import ReturnReason from './ReturnReason';
import ReturningAndResolution from './ReturningAndResolution';
import Issue from './Issue';
import Notes from './Notes';
import styles from '../App.module.scss';
import ShippingRequest from './ShippingRequest';
import SalesForceOppId from './SalesForceOppId';
import SummaryDialog from './SummaryDialog';
import { View } from '../App'

const SLACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_WEBHOOK_URL;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function postToSlack(message: string) {
  // omit headers to avoid CORS error with Slack webhook
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ text: message })
  }
  // TODO: improve error handling
  if (!SLACK_WEBHOOK_URL) {
    console.log('No Slack Webhook url')
    return
  }
  fetch(SLACK_WEBHOOK_URL, requestOptions)
    .catch(err => {
      console.log(err)
    })
}


async function fetchAddressValidation(address: object) {
  try {
    const res = await fetch("https://addressvalidation.googleapis.com/v1:validateAddress?key=" + GOOGLE_MAPS_API_KEY, {
      method: 'POST',
      body: JSON.stringify(address),
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${await res.text()}`);
    }
    const data = await res.json();
    return data
  }
  catch (error) {
    console.error(`Error: ${error}`);
  }
}

function allEqual(arr: Array<string>) {
  return arr.every( v => v === arr[0] )
}

interface IFormInputs {
  oppID: string;
  shippingRequest: string;
  companyName: string;
  shipName: string;
  phoneNumber: string;
  // address: string;
  address1: string;
  address2: string;
  locality: string;
  administrativeArea: string;
  zipCode: string;
  country: string;
  taxID: string;
  returnReason: string;
  issue: string;
  issueNotes: string;
  replace: string;
  replaceEntry: string;
  replaceELR: string;
  replaceELRWM: string;
  replaceOA: string;
  replaceOASR: string;
  replaceOALR: string;
  executiveName: string;
  urgency: string;
  notes: string;
}

interface MainFormProps {
  sensors: Array<CustomCoreSensor> | null;
  nextId: React.MutableRefObject<string>;
  user: CoreUser | null;
  headers: any;
  setView: (value: View) => void;
}

function MainForm(props: MainFormProps) {
  const { sensors, nextId, user, headers, setView } = props;
  const methods = useForm<IFormInputs>()
  const { setFocus, formState: { errors, isSubmitting }} = methods
  const [address, setAddress] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);
  const [addressEntered, setAddressEntered] = useState<string>('');
  const [addressRecommended, setAddressRecommended] = useState<string>('');
  const [finalFormData, setFinalFormData] = useState<Object|null>(null);

  const serials = sensors!.map((s) => s.serial_number)

  let countOA = 0
  let countOASR = 0
  let countOALR = 0
  let countEntry = 0
  let countELR = 0
  if (sensors) {
    sensors.forEach((sensor) => {
      if (sensor.part_name.includes('oa (oa1)')) {
        countOA++
      } else if (sensor.part_name.includes('oasr (oa1)')) {
        countOASR++;
      } else if (sensor.part_name.includes('oalr')) {
        countOALR++;
      } else if (sensor.part_name.includes('entry lr (oa1b)')) {
        countELR++;
      }else if (sensor.part_name.includes('entry')) {
        countEntry++;
      }
    });
  }


  // scroll to first error
  useEffect(() => {
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return !!errors[fieldKey] ? fieldKey : a;
    }, null)

    if (firstError) {
      setFocus(firstError);
    }
  }, [setFocus, errors, isSubmitting])

  function checkAddress(formData: IFormInputs) {
    console.log(formData)
    const searchAddress = {
      "address": {
        "regionCode": "US",
        "locality": formData.locality,
        "addressLines": [formData.address1, formData.address2]
      },
      "enableUspsCass": true
    }
    const validAddresses = fetchAddressValidation(searchAddress)
    validAddresses.then(data => {
      setAddressRecommended(data.result.address.formattedAddress)
      setAddressEntered(`${formData.address1} ${formData.address2} ${formData.locality} ${formData.administrativeArea}`)
      setOpenDialog(true)
      console.log(data)
    })
  }
  
  function checkSummary(data: IFormInputs) {
    const bqData = {
      response_requester_email: user!.email,
      response_customer_name: data.companyName,
      response_customer_org: "",
      response_sf_opportunity_no: data.oppID,
      response_orig_shipping_request_no: data.shippingRequest,
      response_return_reason: data.returnReason,
      response_return_reason_details: data.issue,
      response_return_reason_details_2: data.issueNotes,
      response_resolution_urgency: data.urgency,
      response_return_name: data.shipName,
      response_return_phone: data.phoneNumber,
      response_return_address_1: data.address1,
      response_return_address_2: data.address2,
      response_return_city: data.locality,
      response_return_state: data.administrativeArea,
      response_return_zip: data.zipCode,
      response_return_country: data.country,
      response_details_other: data.notes,
      response_return_qty_oa1: countOA,
      response_return_qty_oasr: countOASR,
      response_return_qty_entry: countEntry,
      response_return_qty_entrylr: countELR,
      response_return_qty_oalr: countOALR,
      response_is_required: data.replace,
      response_requested_qty_oa1: data.replaceOA,
      response_requested_qty_oasr: data.replaceOASR,
      response_requested_qty_entry: data.replaceEntry,
      response_requested_qty_entrylr: data.replaceELR,
      response_requested_qty_entrylr_wm: data.replaceELRWM,
      response_requested_qty_oalr: data.replaceOALR,
      response_exec_approval_approved_by: data.executiveName,
      response_customer_tax_id_ein: data.taxID,
      serial_numbers: serials,
    };
    setFinalFormData(bqData)
    setOpenDialog(true)
  }

  function handleSubmit() {

    // check Slack urgency
    const urgency = methods.watch('urgency')
    let dollarSigns
    if (urgency !== undefined) {
      dollarSigns = urgency.match(/\$/g)
    }
    const dollarSignsCount = dollarSigns ? dollarSigns.length : 0

    const replacementString = methods.watch('replace') ? `Replacement requested (${methods.watch('replaceEntry')} Entry, ${methods.watch('replaceELR')} ELR, ${methods.watch('replaceELRWM')} ELR wall mounts, ${methods.watch('replaceOA')} OA, ${methods.watch('replaceOASR')} OASR, ${methods.watch('replaceOALR')} OALR)\n${urgency}` : 'No replacement needed'
    const userString = `submitted by ${user!.full_name}`
    const moreInfo = '_If you need to inquire or change any information regarding this request, please reach out to logistics referencing the RMA ID_'

    if (sensors) {
      const message = `${dollarSignsCount >= 3 ? ':rotating_light: *URGENT*: ' : ''}New RMA processed for ${sensors.length} ${sensors[0].organization_name} sensor${sensors.length === 1 ? '' : 's'} (${countEntry} Entry, ${countELR} Entry LR, ${countOA} OA, ${countOASR} OASR, ${countOALR} OALR).`
      postToSlack(`${message}\n${nextId.current}\n${replacementString}\n${userString}\n\n${moreInfo}`)
    }

    setView(View.DONE)
  }

  function getDefaultSfOppId() {
    const sfoppids = sensors!.map((s) => s.salesforce_opp_id)
    if (allEqual(sfoppids)) {
      return sfoppids[0]
    } else {
      return ''
    }
  }

  function getDefaultShipReq() {
    const shipreqs = sensors!.map((s) => s.shipping_request)
    if (allEqual(shipreqs) && shipreqs[0] !== "n/a") {
      return shipreqs[0]
    } else {
      return ''
    }
  }

  return (
    <FormProvider {...methods}>
      <form noValidate autoComplete='off' onSubmit={methods.handleSubmit(checkSummary)}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {/* <SalesForceOppId defaultValue={defaultSfOppId}/> */}
            <SalesForceOppId defaultValue={getDefaultSfOppId()}/>
          </Grid>
          <Grid item sm={12}>
            <ShippingRequest defaultValue={getDefaultShipReq()}/>
          </Grid>
          <Grid item sm={12}>
            <ReturnReason/>
          </Grid>
          {methods.watch('returnReason') === "issue" && 
            <Grid item sm={12}>
              <Issue/>
            </Grid>
          }
          <Grid item sm={12}>
            <ReturningAndResolution countEntry={countEntry} countELR={countELR} countOA={countOA} countOASR={countOASR} maxOALR={countOALR}/>
          </Grid>
          <Grid item sm={12}>
            <AddressForm defaultCompany={sensors![0].organization_name}/>
          </Grid>
          <Grid item sm={12}>
            <Notes/>
          </Grid>
          <Grid item sm={12}>
            If the customer is requesting a refund, please submit a <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSflFqKaI5k5cx2rWuw98UASF2PdMcXmDBg76s42lVNnDNFVlg/viewform"
              target="_blank" rel="noopener noreferrer">Refund Request Form</a>.
          </Grid>
          <Grid item sm={12} className={styles.gridButton}>
            <Button
              className={styles.gridButton}
              variant="outlined"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        {/* <AddressDialog
          setAddress={setAddress}
          addressEntered={addressEntered}
          addressRecommended={addressRecommended}
          open={openDialog}
          setOpen={setOpenDialog}
          submitHandler={methods.handleSubmit(handleSubmit)}
        /> */}
        <SummaryDialog
          open={openDialog}
          setOpen={setOpenDialog}
          nextId={nextId}
          finalFormData={finalFormData}
          headers={headers}
          submitHandler={methods.handleSubmit(handleSubmit)}
        />
      </form>
    </FormProvider>
  )
}

export default MainForm;
