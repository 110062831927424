import { FormInputSelect } from './formInputs';

const reasonsForReturn = [
  {
    label: "Churn",
    value: "churn",
  },
  {
    label: "Issue",
    value: "issue",
  },
  {
    label: "Simple Return (unopened)",
    value: "simple return",
  },
  {
    label: "Swap (not Issue, not Churn)",
    value: "swap",
  },
  {
    label: "Demo",
    value: "demo",
  },
  {
    label: "Internal",
    value: "internal",
  },
  {
    label: "Other",
    value: "other",
  },
]

function ReturnReason() {

  return (
    <FormInputSelect
      name="returnReason"
      label="Reason for return"
      options={reasonsForReturn}
      required
    />
  )
}

export default ReturnReason;
