import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  FormGroup,
  Checkbox,
} from '@mui/material';


interface FormInputSelectProps {
  name: string;
  label: string;
  options: Array<any>;
  required?: boolean;
}

interface FormInputTextProps {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  multiline?: boolean;
  pattern?: {
    regex: RegExp,
    message?: string
  }
  longLabelFix?: boolean;
}

interface FormInputInputProps {
  name: string;
  max: number;
  defaultValue?: number;
  required?: boolean;
  label: string;
}

export function FormInputSelect(props: FormInputSelectProps) {
  const { name, label, options, required } = props;
  const { control, formState: { errors } } = useFormContext();

  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{required: required}}
      render={({ field: { onChange, value, ref } }) => (
        <FormControl fullWidth variant="outlined" error={!!errors[name]}>
          <InputLabel id={label}>{label}</InputLabel>
          <Select onChange={onChange} value={value} label={label} required={required} inputRef={ref}>
            {generateSelectOptions()}
          </Select>
          {/* <FormHelperText>{errors[name] ? errors[name]?.message : ''}</FormHelperText> */}
          <FormHelperText>{errors[name] ? 'This field is required' : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
};

export function FormInputInput(props: FormInputInputProps) {
  const { name, max, defaultValue, required, label } = props;
  const { control, setValue } = useFormContext();

  const handleBlur = (e: any) => {
    const inputValue = parseInt(e.target.value)
    if (inputValue < 0) {
      setValue(name, 0)
    } else if (inputValue > max) {
      setValue(name, max)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || 0}
      rules={{required: required}}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextField onChange={onChange}value={value} label={label} required={required || false} variant='standard' fullWidth inputProps={{ min: 0, max: max, step: 1, type: 'number'}} InputLabelProps={{style: {fontSize: 20,}}}
         onBlur={(e) => {
          onBlur()
          handleBlur(e)
         }}
         inputRef={ref}
        />
      )}
    />
  )
};

export function FormInputText(props: FormInputTextProps) {
  const { name, label, defaultValue, required, multiline, pattern, longLabelFix } = props;
  const { control, formState: { errors } } = useFormContext();
  const rules: any = { required: required ? 'This field is required' : false};

  if (pattern) {
    rules.pattern = {
      value: pattern.regex,
      message: pattern.message || 'Invalid input'
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <TextField onChange={onChange} value={value} label={label} fullWidth required={required || false} multiline={multiline || longLabelFix || false} rows={multiline ? 4 : 1}
          error={!!errors[name]}
          inputRef={ref}
          helperText={errors[name]?.message as React.ReactNode}
        />
      )}
    />
  )
};

export function FormInputRadio(props: FormInputSelectProps) {
  const { name, label, options, required } = props;
  const { control, formState: { errors } } = useFormContext();

  const generateRadioOptions = () => {
    return options.map((option) => {
      return (
        <FormControlLabel
          key={option.value}
          value={option.value}
          label={option.label}
          control={<Radio required={required}/>}
        />
      );
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{required: required}}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth variant="outlined" error={!!errors[name]}>
          <FormLabel id={label}>{label}</FormLabel>
          <RadioGroup value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
          <FormHelperText>{errors[name] ? 'This field is required' : ''}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export function FormInputCheckbox(props: FormInputTextProps) {
  const { name, label, defaultValue } = props;
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || false}
      render={({ field: { onChange, value } }) => (
        <FormControl
          error={!!errors[name]}
          // helperText={errors[name] ? 'This field is required': ''}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={onChange} checked={value} />}
              label={label}
            />
          </FormGroup>
        </FormControl>
      )}
    />
  )
};
