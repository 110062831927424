import { View } from '../../App'

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_ENV = process.env.REACT_APP_ENV;

export async function getSensorTable(
  headers: any, serial_numbers: Array<string>,
  dataSetter: React.Dispatch<React.SetStateAction<any>>,
  viewSetter: React.Dispatch<React.SetStateAction<any>>) {
  const res = await fetch(REACT_APP_API_BASE_URL! + "/v1/sensor-table/" + serial_numbers, {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    viewSetter(View.ERROR)
    // throw new Error(`${res.status}: ${await res.text()}`);
  }
  const data = await res.json()
  dataSetter(data)
}


export async function createRMA(headers: any, data: any, errorSetter: React.Dispatch<React.SetStateAction<any>>, idRef: React.MutableRefObject<string>) {
  headers['Content-Type'] = 'application/json';
  const endpoint = REACT_APP_ENV === 'test' ? "/v1/rma-test" : "/v1/rma";
  const res = await fetch(REACT_APP_API_BASE_URL! + endpoint, {
    method: 'POST',
    body: JSON.stringify(data),
    headers,
  });
  if (!res.ok) {
    errorSetter(true)
    throw new Error(`${res.status}: ${await res.text()}`)
  } else {
    const data = await res.json()
    idRef.current = data.id
    errorSetter(false)
  }
}
