import React, { useState } from 'react';
import {
  TextField,
  Button,
} from "@mui/material";
import styles from '../App.module.scss';


// check top level serials, including Krypton S5
function validateSerial(input: string): Array<string> | null {
  const expression = /([A-Z][0-9][A-Z]{3}[0-9]{3})|(K[0-9]{4}S5A[0-9]{3}[A-Z]{1})/g;
  return input.toUpperCase().match(expression)
};

interface InputSerialsProps {
  handleSubmit: any;
}

function InputSerials(props: InputSerialsProps) {
  const { handleSubmit } = props;
  const [serialsText, setSerialsText] = useState<string>('');
  const [serials, setserials] = useState<Array<string> | null>(null);
  const [inputError, setInputError] = useState(false);
  const [message, setMessage] = useState<string>('');

  function handleChange(event: any) {
    setSerialsText(event.target.value)
    setInputError(true)
    const found = validateSerial(event.target.value)
    setInputError(!found)
    if (found) {
      const unique = Array.from(new Set(found))
      setserials(unique)
      setMessage(`Found ${unique.length} serial number${unique.length === 1 ? '' : 's'}`)
    } else {
      setMessage('No serial numbers found')
    }
  }
  function handleSubmitLocal(event: React.FormEvent) {
    event.preventDefault();

    setInputError(false);

    if (!serials) {
      setInputError(true);
      return
    }

    handleSubmit(serials);
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmitLocal}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Serial numbers"
        variant="outlined"
        required
        multiline
        rows={4}
        error={inputError}
        helperText={message}
        value={serialsText}
        onChange={handleChange}
      />
      <div className={styles.button}>
        <Button
          type="submit"
          variant="outlined"
          disabled={inputError || !serials}
        >
          Next Step
        </Button>
      </div>
    </form>
  )
}

export default InputSerials;