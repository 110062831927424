import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInputRadio, FormInputCheckbox, FormInputInput, FormInputText } from './formInputs';
import { Grid } from '@mui/material';


const urgencyOptions = [
  {
    label: "$$$$$ - First Overnight 8AM",
    value: "$$$$$ - First Overnight 8AM",
  },
  {
    label: "$$$$ - Priority Overnight 10:30AM",
    value: "$$$$ - Priority Overnight 10:30AM",
  },
  {
    label: "$$$ - Standard Overnight 4:30PM",
    value: "$$$ - Standard Overnight 4:30PM",
  },
  {
    label: "$$ - 2Day 10:30AM",
    value: "$$ - 2Day 10:30AM",
  },
  {
    label: "$$ - 2Day 4:30PM",
    value: "$$ - 2Day 4:30PM",
  },
  {
    label: "$ - Ground 2-5 days",
    value: "$ - Ground 2-5 days",
  },
  {
    label: "$$$$ - International Priority",
    value: "$$$$ - International Priority",
  },
  {
    label: "$$$ - International Economy",
    value: "$$$ - International Economy",
  },
];

function ReturningAndResolution({ countEntry, countELR, countOA, countOASR, maxOALR }: { countEntry: number, countELR: number, countOA: number, countOASR: number, maxOALR: number }) {
  const { watch, setValue } = useFormContext();
  const watchCheckbox = watch('replace');
  const watchEnteredOA = watch('replaceOA', 0);
  const watchEnteredOASR = watch('replaceOASR');
  const watchEnteredEntry = watch('replaceEntry', 0);
  const watchEnteredELR = watch('replaceELR');
  const watchEnteredELRWM = watch('replaceELRWM');
  const [maxELR, setMaxELR] = useState(countELR + countEntry);
  const [maxEntry, setMaxEntry] = useState(0);
  const [maxOASR, setMaxOASR] = useState(countOASR + countOA);
  const [maxOA, setMaxOA] = useState(0);

  useEffect(() => {
    if (!watchCheckbox) {
      setValue('replaceEntry', 0);
      setValue('replaceELR', 0);
      setValue('replaceELRWM', 0);
      setValue('replaceOA', 0);
      setValue('replaceOASR', 0);
      setValue('replaceOALR', 0);
      setValue('executiveName', '');
    } else {
      setValue('replaceEntry', 0);
      setValue('replaceELR', countEntry + countELR);
      setValue('replaceELRWM', countEntry + countELR);
      setValue('replaceOA', countOA);
      setValue('replaceOASR', countOASR);
      setValue('replaceOALR', maxOALR);
    }
  }, [watchCheckbox, setValue, countEntry, countELR, countOA, countOASR, maxOALR]);

  useEffect(() => {
    const currentmaxOASR = countOASR + countOA - watchEnteredOA;
    const currentMaxOA = Math.min(countOASR + countOA - watchEnteredOASR, countOA);
    setMaxOASR(currentmaxOASR);
    setMaxOA(currentMaxOA);
  }, [watchEnteredOA, watchEnteredOASR, maxOASR, maxOA, countOASR, countOA]);

  useEffect(() => {
    const currentMaxELR = countELR + countEntry - watchEnteredEntry;
    const currentMaxEntry = countELR + countEntry - watchEnteredELR;
    setMaxELR(currentMaxELR);
    setMaxEntry(currentMaxEntry);
  }, [watchEnteredEntry, watchEnteredELR, maxELR, maxEntry, countELR, countEntry]);

  useEffect(() => {
    if (watchEnteredELRWM > watchEnteredELR) {
      setValue('replaceELRWM', watchEnteredELR)
    }
  }, [watchEnteredELRWM, watchEnteredELR, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <FormInputCheckbox
        name="replace"
        label="Ship a replacement?"
        />
      </Grid>
      {watchCheckbox &&
      <>
        <Grid item sm={12}>
          How many?
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceEntry"
            label="Entry"
            max={maxEntry}
            required
          />
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceELR"
            label="Entry LR"
            max={maxELR}
            required
          />
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceELRWM"
            label="wall mount"
            max={maxELR}
            required
          />
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceOA"
            label="OA"
            max={maxOA}
            required
          />
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceOASR"
            label="OASR"
            max={maxOASR}
            required
          />
        </Grid>
        <Grid item sm={2.0}>
          <FormInputInput
            name="replaceOALR"
            label="OALR"
            max={maxOALR}
            required
          />
        </Grid>
        {watchEnteredEntry > 0 &&
          <Grid item sm={12}>
            <FormInputText
              name="executiveName"
              label="Name of executive who approved entry (s5b) replacement"
              longLabelFix
              required
              pattern={{
                regex: /^[a-zA-Z' -]{3,}$/,
                message: 'Invalid name'
              }}
            />
          </Grid>
        }
        <Grid item sm={12}>
          <FormInputRadio
          name="urgency"
          label="Urgency"
          options={urgencyOptions}
          required
          />
        </Grid>
      </>
      }
    </Grid>
  )
}

export default ReturningAndResolution;
