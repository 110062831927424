import { FormInputText } from './formInputs';


function Notes() {

  return (
    <FormInputText
      name="notes"
      label="Please provide any other details"
      multiline
    />
  )
}

export default Notes;
