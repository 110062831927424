import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
  } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { createRMA } from '../lib/api/backend';
import styles from '../App.module.scss';

export interface SummaryDialogProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    nextId: React.MutableRefObject<string>;
    finalFormData: any;
    headers: any;
    submitHandler: () => void;
  }

export default function SummaryDialog(props: SummaryDialogProps) {
  const { open, setOpen, nextId, finalFormData, headers, submitHandler } = props;
  const [loading, setLoading] = useState(true);
  const [bqError, setBQError] = useState<boolean | null>(null);
  const [showError, setShowError] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (event: any) => {
    setLoading(true)
    createRMA(headers, finalFormData, setBQError, nextId)
  }

  useEffect(() => {
    setLoading(false)
    if (bqError) {
      console.log('Error communicating with BigQuery')
      setShowError(true)
      setBQError(null)
    } else if (bqError === false) {
      console.log('success')
      setShowError(false)
      setOpen(false)
      submitHandler()
      setBQError(null)
    }
  }, [bqError, setOpen, submitHandler])

    function Summary() {
      return (
        <>
          <p>{finalFormData.response_return_qty_oa1} OA, {finalFormData.response_return_qty_oasr} OASR, {finalFormData.response_return_qty_oalr} OALR, {finalFormData.response_return_qty_entry} Entry, {finalFormData.response_return_qty_entrylr} Entry LR sensors</p>
          <p>salesforce opportunity #: {finalFormData.response_sf_opportunity_no === '' ? 'none' : finalFormData.response_sf_opportunity_no}</p>
          <p>shipping request: {finalFormData.response_orig_shipping_request_no === '' ? 'none' : finalFormData.response_orig_shipping_request_no}</p>
          <p>reason for return: {finalFormData.response_return_reason}</p>
          <p>replace: {finalFormData.response_is_required === true ? `${finalFormData.response_requested_qty_oa1} OA, ${finalFormData.response_requested_qty_oasr} OASR, ${finalFormData.response_requested_qty_oalr} OALR, ${finalFormData.response_requested_qty_entry} Entry, ${finalFormData.response_requested_qty_entrylr} Entry LR sensors, ${finalFormData.response_requested_qty_entrylr_wm} wall mounts` : 'no'}</p>
          <p>address:</p>
          <div className={styles.summaryAddress}>
            <p> {finalFormData.response_return_name}, {finalFormData.response_return_phone}</p>
            <p>{finalFormData.response_return_address_1}</p>
            <p>{finalFormData.response_return_address2}</p>
            <p>{finalFormData.response_return_city}, {finalFormData.response_return_state} {finalFormData.response_return_zip}</p>
            <p>{finalFormData.response_return_country}</p>
          </div>
          <p>EIN: {finalFormData.response_customer_tax_id_ein === '' ? 'none' : finalFormData.response_customer_tax_id_ein}</p>
        </>
      )
    }
  
    return (
      <Dialog onClose={handleClose} open={open} sx={{alignItems: 'flex-start'}}>
        <DialogTitle>Does this look right?</DialogTitle>
        <DialogContent>
          {finalFormData && <Summary />}
          {showError && <Alert severity="error">Something went wrong. Please contact Logistics.</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            onClick={handleConfirm}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Send</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }