import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormInputSelect, FormInputText } from './formInputs';
import {
  Grid,
} from '@mui/material';

const issueOptionsForIssue = [
  {
    label: "Performance Issue - Does not stream frames",
    value: "Performance Issue - Does not stream frames",
  },
  {
    label: "Performance Issue - Bad data quality",
    value: "Performance Issue - Bad data quality",
  },
  {
    label: "Performance Issue - Loud fan",
    value: "Performance Issue - Loud fan",
  },
  {
    label: "Packaging or device was damaged in shipping",
    value: "Packaging or device was damaged in shipping",
  },
  {
    label: "Would not provision (Setup App issue, etc)",
    value: "Would not provision (Setup App issue, etc)",
  },
  {
    label: "Networking issue (cannot connect to internet, etc)",
    value: "Networking issue (cannot connect to internet, etc)",
  },
  {
    label: "Would not power on",
    value: "Would not power on",
  },
  {
    label: "Other",
    value: "Other",
  },
]

function Issue() {
  const { watch, resetField } = useFormContext();
  const returnReason = watch("returnReason");

  useEffect(() => {
    resetField("issue")
    return () => {
      resetField("issue")
    };
  }, [returnReason, resetField]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <FormInputSelect
          name="issue"
          label="What is the issue?"
          options={issueOptionsForIssue}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <FormInputText
          name="issueNotes"
          label="What symptoms does the the customer report?"
          multiline
        />
      </Grid>
    </Grid>
  )
}

export default Issue;
