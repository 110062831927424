import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { TokenProvider } from '@densityco/lib-common-auth';
import { CoreUser } from '@densityco/lib-api-types';
import {
  Button,
  Alert,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CustomCoreSensor } from './lib/types';
import { getUser } from './lib/api/core';
import { getSensorTable } from './lib/api/backend';
import InputSerials from './components/InputSerials';
import ContentLoading from './components/ContentLoading';
import AutoFilledSensorTable from './components/SensorTable';
import MainForm from './components/MainForm';
import logo from './lib/logo_mark_black.png';
import DoneIcon from '@mui/icons-material/Done';
import styles from './App.module.scss';


export enum View {
  INPUT = "input",
  PENDING = "pending",
  FOUND_SENSORS = "found sensors",
  ERROR = "error",
  BAD_ORG = "bad organization",
  DONE = "done",
}

type Credentials = {
  headers: { [key: string]: string };
};

function allEqual(arr: Array<string>) {
  return arr.every( v => v === arr[0] )
}

function AppView({ headers }: Credentials) {
  const [view, setView] = useState<View>(View.INPUT);
  const [sensors , setSensors] = useState<Array<CustomCoreSensor> | null>(null);
  const [user, setUser] = useState<CoreUser | null>(null);
  const nextId = useRef<string>('');

  const [progress, setProgress] = useState(0);
  const timeoutSeconds = 11
  const intervalMilliseconds = 500

  useEffect(() => {
    getUser(headers, setUser)
  }, [headers])

  function handleSubmit(serials: Array<string>) {
    setView(View.PENDING)
    getSensorTable(headers, serials, setSensors, setView)
  }

  function handleStartOver() {
    setSensors(null);
    setView(View.INPUT);
  }

  // user input sensors and moved to the next step
  // TODO: test with properly formatted but nonexistent serial numbers
  useEffect(() => {
    if (user && sensors) {
      // check if the sensors are from the same org
      const orgs = sensors.map((s) => s.organization_name)
      if (allEqual(orgs)) {
        setView(View.FOUND_SENSORS)
      } else {
        setView(View.BAD_ORG)
      }
    }
  }, [user, sensors])

  // set timer if PENDING
  useEffect(() => {
    let interval: NodeJS.Timer;
    let timer: NodeJS.Timeout;
    if (view === View.PENDING) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          return oldProgress + intervalMilliseconds/(timeoutSeconds*1000)*100
        });
      }, intervalMilliseconds);

      timer = setTimeout(() => {
        clearInterval(interval);
        setView(View.ERROR);
      }, timeoutSeconds * 1000);
    } else {
      clearInterval(interval!)
      clearTimeout(timer!)
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
      setProgress(0);
    };
  }, [view]);

  function SurveyHeader() {
    return (
      <Grid container alignItems="center" spacing={2}>
        {/* <Grid item className={styles.graphicsContainer}> */}
        <Grid item>
          <img className={styles.staticDensityLogo} src={logo} alt="graphic1" />
        </Grid>
        <Grid item>
          <Typography variant='h4'>Density RMA Survey</Typography>
        </Grid>
        <Grid item sm={12}>
        <Typography variant='h5' gutterBottom>for Density CX only</Typography>
        </Grid>
        <Grid item sm={12}>
        {/* <p>A0DJZ002,A0DKE007,A0DKF015,A2FDR002,A2FDR004</p> */}
        {user && <Typography variant='body2' gutterBottom style={{ color: grey[700] }}>logged in as {user!.full_name}</Typography>}
        </Grid>
      </Grid>
    )
  }

  function InputView() {
    return (
      <>
        <SurveyHeader />
        <InputSerials handleSubmit={handleSubmit} />
      </>
    )
  }

  function FoundSensorsView() {
    return (
      <>
        <SurveyHeader />
        <AutoFilledSensorTable sensors={sensors} />
        <Box height={30}/>
        <MainForm
          sensors={sensors}
          nextId={nextId}
          user={user}
          headers={headers}
          setView={setView}
        />
      </>
    )
  }

  function BadOrgView() {
    return (
      <>
        <AutoFilledSensorTable sensors={sensors} />
        <Alert className={styles.button} severity="warning">These sensors do not belong to the same customer. Please create separate RMAs for each customer.</Alert>
        <Button
          variant="outlined"
          onClick={handleStartOver}
        >
          Start Over
        </Button>
      </>
    )
  }

  function ErrorView() {
    return (
      <>
        <Alert className={styles.button} severity="error">There was an error fetching sensor info.</Alert>
        <Button
          variant="outlined"
          onClick={handleStartOver}
        >
          Start Over
        </Button>
      </>
    )
  }

  function DoneView() {
    const iconStyle = {
      fontSize: '3.75rem', // This is equivalent to the default h2 font size in Material-UI
      verticalAlign: 'text-bottom',
    }
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item className={styles.graphicsContainer}>
          <img className={`${styles.graphic} ${styles.graphic1}`} src={logo} alt="graphic1" />
          <DoneIcon className={`${styles.graphic} ${styles.graphic2}`} style={iconStyle}/>
        </Grid>
        <Grid item>
          <Typography variant='h2'>Done!</Typography>
        </Grid>
        <Grid item sm={12}>
        <Typography variant='h5'>{nextId.current} sent to the Operations team!</Typography>
        </Grid>
        <Grid item sm={12}>
        <p>If you need to inquire or change any information regarding this request, ping Logistics in the Slack channel #ops-rma together with the ticket number {nextId.current}</p>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={styles.container}>
      {view === View.INPUT && <InputView />}
      {view === View.PENDING && <ContentLoading progress={progress} />}
      {view === View.FOUND_SENSORS && <FoundSensorsView />}
      {view === View.BAD_ORG && <BadOrgView />}
      {view === View.ERROR && <ErrorView />}
      {view === View.DONE && <DoneView />}
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <TokenProvider
        fallback={<p className={styles.loading}>Loading</p>}
        children={({ headers }) => {
          const modifiedHeaders = { ...headers };
          delete modifiedHeaders['X-Impersonate-User'];
          return (
            <AppView
              headers={modifiedHeaders}
            />
          );
        }}
      />
    </div>
  );
}

export default App;
