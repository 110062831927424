
import { CustomCoreSensor } from '../lib/types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

interface AutoFilledSensorTableProps {
  sensors: Array<CustomCoreSensor> | null;
}

function AutoFilledSensorTable(props: AutoFilledSensorTableProps) {
  const { sensors } = props;
  const tableTitle = ['Serial Number', 'Type', 'Company', 'Status', 'Salesforce Opp ID', 'Shipping Request'];

  return sensors ? (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableTitle.map(title => <TableCell key={title}>{title}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {sensors.map(s => (
            <TableRow key={s.serial_number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell key={s.serial_number}>{s.serial_number}</TableCell>
              <TableCell key={s.part_name}>{s.part_name}</TableCell>
              <TableCell key={s.organization_name}>{s.organization_name}</TableCell>
              <TableCell key={s.current_status}>{s.current_status}</TableCell>
              <TableCell key={s.salesforce_opp_id}>{s.salesforce_opp_id}</TableCell>
              <TableCell key={s.shipping_request}>{s.shipping_request}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>Missing sensor info</p>
  )
}

export default AutoFilledSensorTable;
